import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import App from "../views/App/App";
import LoginForm from "../views/auth/LoginForm";
import RegisterForm from "../views/auth/RegisterForm";
import NotFound from "../views/NotFound";
import AuthLayout from "../views/layouts/AuthLayout/AuthLayout";
import MainLayout from "../views/layouts/MainLayout/MainLayout";
import { useTranslation } from "react-i18next";

const Routes = () => {
    const { t } = useTranslation();
    return <Router>
        <Switch>
            <Route path='/login'>
                <AuthLayout title={t('Login')}><LoginForm /></AuthLayout>
            </Route>
            <Route path='/register'>
                <AuthLayout title={t('Register')}><RegisterForm /></AuthLayout>
            </Route>
            <Route exact path="/">
                <MainLayout title={t('App')}><App /></MainLayout>
            </Route>
            <Route path="*">
                <MainLayout title={t('Not found')}><NotFound /></MainLayout>
            </Route>
        </Switch>
    </Router>
}

export default Routes;