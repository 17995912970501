import { Container, Row, Col, Navbar, Nav } from "react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LangSelector from "../../../components/LangSelector";
import './MainLayout.scss';
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../redux/authSlice";
import { useLogout } from "../../../hooks/api";

const LoggedInLinks = ({ t }) => {
    const dispatch = useDispatch();
    const logoutCallback = useLogout();
    const logoutAction = () => {
        logoutCallback().then((response) => {
            if (response.status === 200) {
                dispatch(logout());
            }
        });
    }
    return <>
        <Link to={'/dashboard'} className={'nav-link'}>{t('Dashboard')}</Link>
        <Link to={'#logout'} className={'nav-link'} onClick={logoutAction}>{t('Logout')}</Link>
    </>;
}

const LoggedOutLinks = ({ t }) => {
    return <>
        <Link to={'/login'} className={'nav-link'}>{t('Login')}</Link>
        <Link to={'/register'} className={'nav-link'}>{t('Register')}</Link>
    </>;
}

const Navigation = () => {
    const { t } = useTranslation();
    const { isLoggedIn } = useSelector((state) => state.auth);

    return <Navbar bg="dark" expand={'lg'} variant="dark" className={'fixed-top'}>
        <Container>
            <Link to={'/'} className={'navbar-brand'}>{t('Home')}</Link>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                    {isLoggedIn &&
                        <LoggedInLinks t={t} />
                    }
                    {!isLoggedIn &&
                        <LoggedOutLinks t={t} />
                    }
                </Nav>
                <LangSelector />
            </Navbar.Collapse>
        </Container>
    </Navbar>;
}

const MainLayout = ({ children, title }) => {
    document.title = title;
    return <div className={'main-layout'}>
        <Navigation />
        <main className={'pt-5 mt-2'}>
            <Container>
                <Row>
                    <Col xl={12}>
                        {children}
                    </Col>
                </Row>
            </Container>
        </main>
    </div>;
}

export default MainLayout;