import React from 'react';
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';


const LangSelector = () => {
    const { t } = useTranslation();

    const changeLanguage = (lng, event) => {
        event.preventDefault();
        i18n.changeLanguage(lng);
    }

    const langs = [
        { code: 'pl', flag: 'pl', label: t('Polish') },
        { code: 'en', flag: 'gb', label: t('English') }
    ];

    let selectedLang;

    for (var k in langs) {
        if (langs[k].code === i18n.language) {
            selectedLang = langs[k];
        }
    }

    const ButtonContent = ({ flag, label, code }) => {
        return <>
            <img width="16" className={'me-1'} src={'/resources/img/flags/' + flag + '.svg'} alt={code} />
            {label}
        </>
    }

    const mapFlags = (props, key) => {
        return <Dropdown.Item key={key} href="#"
            onClick={(event) => changeLanguage(props.code, event)}
        >
            <ButtonContent {...props} />
        </Dropdown.Item>;
    }

    return (
        <div className={'lang-selector'}>
            <Dropdown>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    <ButtonContent {...selectedLang} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {langs.map(mapFlags)}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default LangSelector;