import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    token: null,
    user: null,
    isLoggedIn: false
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action) => {
            state.user = action.payload.user;
            state.token = action.payload.token;
            state.isLoggedIn = typeof action.payload.user !== 'undefined'
        },
        logout: (state) => {
            state.token = null;
            state.user = null;
            state.isLoggedIn = false;
        },
    },
})

// Action creators are generated for each case reducer function
export const { login, logout } = authSlice.actions

export default authSlice.reducer