import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import pl from './locales/pl/translation.json';
import en from './locales/en/translation.json';

console.log(process.env.REACT_APP_COOKIE_DOMAIN);
i18n
    .use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: { en: { translation: en }, pl: { translation: pl } },
        fallbackLng: "pl",
        useCookie: true,
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        detection: {
            order: ['cookie', 'querystring', 'header'],
            lookupCookie: 'lang',
            lookupQuerystring: 'lang',
            cookieDomain: process.env.REACT_APP_COOKIE_DOMAIN,
            caches: ['cookie']
        }
    });

export default i18n;