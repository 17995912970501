import React from 'react';
import { Formik, Form } from 'formik';
import { Button } from 'react-bootstrap';
import { useApi } from '../../hooks/api';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/authSlice';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Input from '../../components/form/Input';
import { useTranslation } from 'react-i18next';

const initialValues = { email: '', password: '' };

const FormComponent = ({ isSubmitting }) => {
    const { t } = useTranslation();

    return <Form>
        <h2 className={'mb-4'}>{t('Login')}</h2>
        <Input type="email" label={t('Email')} name={'email'} />
        <Input type="password" label={t('Password')} name={'password'} />
        <Button type="submit" disabled={isSubmitting}>
            {t('Click to log in')}
        </Button>
    </Form>
}

const LoginForm = () => {
    const api = useApi({ isGuest: true, suppressAll: false });
    const dispatch = useDispatch();
    const history = useHistory();

    const onSubmit = (values, actions) => {
        api.post('/api/login', values).then((response) => {
            if (response.status === 200) {
                dispatch(login({ user: response.data.user, token: response.data.token }));
                history.push('/');
            }
            actions.setSubmitting(false);
        })
    }

    return <div className={'d-flex flex-column justify-content-center'}>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {(props) => <FormComponent {...props} />}
        </Formik>
    </div>
}

export default LoginForm;