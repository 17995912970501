import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    toasts: [],
    lastId: -1
}

export const toastSlice = createSlice({
    name: 'toasts',
    initialState,
    reducers: {
        addToast: (state, action) => {
            const toast = action.payload;

            state.toasts[state.lastId + 1] = toast;
            state.lastId++;
        },
        removeToast: (state, action) => {
            if (typeof state.toasts[action.payload] !== 'undefined') {
                delete state.toasts[action.payload];
            }
        }
    },
})

// Action creators are generated for each case reducer function
export const { addToast, removeToast } = toastSlice.actions

export default toastSlice.reducer