import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './AuthLayout.scss';

const AuthLayout = ({ children, title }) => {
    document.title = title;

    return <div className={'layout'}>
        <div className={'position-relative'}>
            <div className={'large-background-text'}>
                {title}
            </div>
        </div>
        <Container>
            <Row>
                <Col xl={{ span: 6, offset: 3 }}>
                    <div className={'auth-form-container py-5'}>
                        <Row>
                            <Col xl={{ span: 10, offset: 1 }}>
                                {children}
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
}

export default AuthLayout;