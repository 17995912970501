import './App.css';
import React, { useEffect } from 'react';
import logo from './logo.svg';
import { useApi } from '../../hooks/api';
import { useTranslation } from 'react-i18next';

function App() {
  const api = useApi({ suppressError: true });
  const { t } = useTranslation();

  useEffect(() => {
    api.get('api/me').then((data) => {
      console.log(data);
    });
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          {t('We\'re missing a front page, be back soon!')}
        </p>
      </header>
    </div>
  );
}

export default App;
