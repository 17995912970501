import React from "react";
import Routes from "./Routes";

import { store } from '../redux/store'
import { Provider } from 'react-redux'
import Toasts from "../components/Toasts";
import { withTranslation } from "react-i18next";

const Skeleton = () => {
    return <>
        <Provider store={store}>
            <Routes />
            <Toasts />
        </Provider>
    </>
};

export default withTranslation()(Skeleton);