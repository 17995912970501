import { configureStore } from '@reduxjs/toolkit'
import authReducer from './authSlice';
import toastReducer from './toastSlice';
import { setWithExpiry, getWithExpiry } from '../hooks/localStorageWithExpiry';
import { KEYS } from '../config/localStorageData';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        toasts: toastReducer
    },
    preloadedState: {
        auth: getWithExpiry(KEYS.AUTH)
    }
})

store.subscribe(() => {
    setWithExpiry(KEYS.AUTH, store.getState().auth);
});