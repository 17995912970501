
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer, Toast } from "react-bootstrap";
import { removeToast } from '../redux/toastSlice';
import { useTranslation } from 'react-i18next';

const getTimeString = (currentTime, time, t) => {
    const milisecondsAgo = currentTime - time;
    const secondsAgo = Math.floor(milisecondsAgo / 1000);
    const minutesAgo = Math.floor(secondsAgo / 60);

    if (minutesAgo > 0) {
        return t('{{count}} minutes ago', { count: minutesAgo });
    }
    if (secondsAgo > 0) {
        return t('{{count}} seconds ago', { count: secondsAgo });
    }

    return t('just now');
}

const getTitle = (type, t) => {
    if (type === 'warn') {
        return t('Warning');
    }
    if (type === 'error') {
        return t('Error');
    }

    return t('Notification');
}

const getVariant = (type) => {

    if (type === 'warn') {
        return 'warning'
    }
    if (type === 'error') {
        return 'danger'
    }

    return 'info';
}

const SingleToast = ({ currentTime, toast, onClose }) => {
    const { t } = useTranslation();
    const timeString = getTimeString(currentTime, toast.time, t);
    const title = getTitle(toast.type, t);

    return <Toast show={true} onClose={onClose} bg={getVariant(toast.type)}>
        <Toast.Header>
            <strong className={'me-auto'}>{title}</strong>
            <small className="text-muted">{timeString}</small>
        </Toast.Header>
        <Toast.Body>
            {toast.message}
        </Toast.Body>
    </Toast>
}

const Toasts = () => {
    const { toasts } = useSelector((state) => state.toasts);
    const dispatch = useDispatch();
    const [currentTime, setCurrentTime] = useState(Date.now());

    const onClose = (index) => {
        return () => {
            dispatch(removeToast(index));
        }
    }

    useEffect(() => {
        setInterval(() => {
            setCurrentTime(Date.now());
        }, 1000)
    }, []);

    return <>
        <ToastContainer>
            {toasts.map((toast, index) => {
                return <SingleToast
                    key={index}
                    toast={toast}
                    currentTime={currentTime}
                    onClose={onClose(index)}
                />
            })}
        </ToastContainer>
    </>
};

export default Toasts;