import axios from 'axios';
import { useSelector } from 'react-redux'
import useToast from './useToast';
import { refreshKey } from './localStorageWithExpiry';
import { KEYS } from '../config/localStorageData';

export const useApi = ({ isGuest, suppressAll, suppressSuccess, suppressError } = {}) => {
    const state = useSelector((state) => state.auth)
    const authorization = isGuest || !state.token ? '' : ("Bearer " + state.token);
    const toastService = useToast();

    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_SERVER,
        validateStatus: () => true,
        withCredentials: true,
        headers: {
            Authorization: authorization
        }
    });

    axiosInstance.interceptors.response.use((response) => {
        if (!isGuest && response.status === 200) {
            refreshKey(KEYS.AUTH)
        }
        showMessage(response, toastService, suppressAll, suppressSuccess, suppressError);
        showErrors(response, toastService, suppressAll, suppressSuccess, suppressError);

        return response;
    }, (response) => {
        return response;
    });

    return axiosInstance;
};

export const useLogout = () => {
    const state = useSelector((state) => state.auth)
    const api = useApi();

    return () => api.get('api/logout', { params: { token: state.token } });
};

const showErrors = (response, toastService, suppressAll, suppressSuccess, suppressError) => {
    const type = 'error';

    if (suppressAll || suppressError) {
        return;
    }
    if (typeof response?.data?.errors !== 'undefined') {
        for (var k in response.data.errors) {
            for (var l in response.data.errors[k]) {
                toastService.add({ message: response.data.errors[k][l], type })
            }
        }
    }
}

const showMessage = (response, toastService, suppressAll, suppressSuccess, suppressError) => {
    let type = '';
    const isError = response?.status !== 200;

    if (!suppressAll && typeof response.data.message !== 'undefined') {
        if (response?.status === 400) {
            type = 'warn'
        }
        if (response?.status === 403 || response?.status === 500) {
            type = 'error'
        }
        if ((isError && !suppressError) || (!isError && !suppressSuccess)) {
            toastService.add({ message: response.data.message, type });
        }
    }
}