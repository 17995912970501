import React from 'react';
import { Field, ErrorMessage } from 'formik';

const Input = ({ className, label, name, ...otherProps }) => {
    const id = otherProps.id || name;

    return <div className={'field-holder mb-2'}>
        <label htmlFor={id}>{label}</label>
        <Field id={id} name={name} className={className} {...otherProps} />
        <ErrorMessage name={name} component="div" className={className} />
    </div>;
}

Input.defaultProps = {
    className: 'form-control'
}

export default Input;