import React from 'react';
import { Formik, Form } from 'formik';
import { Button } from 'react-bootstrap';
import { useApi } from '../../hooks/api';
import Input from '../../components/form/Input';
import { useTranslation } from 'react-i18next';

const initialValues = { name: '', email: '', password: '' };

const FormComponent = ({ isSubmitting }) => {
    const { t } = useTranslation();
    document.title = t('Register');

    return <Form>
        <h2 className={'mb-4'}>{t('Register')}</h2>
        <Input name={'name'} label={t('Name')} type={'text'} />
        <Input name={'email'} label={t('Email')} type={'email'} />
        <Input name={'password'} label={t('Password')} type={'password'} />
        <Button type="submit" disabled={isSubmitting}>
            {t('Click to register')}
        </Button>
    </Form>
}

const RegisterForm = () => {
    const api = useApi();

    const onSubmit = (values, actions) => {
        api.post('/api/register', values).then((response) => {
            console.log(response);
            actions.setSubmitting(false);
        })
    }

    return <div className={'d-flex flex-column justify-content-center'}>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {(props) => <FormComponent {...props} />}
        </Formik>
    </div>
}

export default RegisterForm;