import React from "react";
import { useTranslation } from "react-i18next";

const NotFound = () => {
    const { t } = useTranslation();

    return <main>
        {t('404')}
    </main>
};

export default NotFound;