import { addToast, removeToast } from '../redux/toastSlice';
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react';

const defaults = {
    add: { message: '', type: '', time: 10000 }
}

const useToast = () => {
    const state = useSelector((state) => state.toasts);
    const dispatch = useDispatch();

    useEffect(() => {
        const toast = state.toasts[state.lastId] ?? null;

        if (toast === null) {
            return;
        }

        if (toast.duration !== null) {
            setTimeout(() => {
                dispatch(removeToast(state.lastId));
            }, toast.duration);
        }
    }, [state.lastId]);

    return {
        add: (params) => {
            const { message, type, time } = { ...defaults.add, ...params };
            dispatch(addToast({ message: message, type: type, time: Date.now(), duration: time }));
        }
    }
}

export default useToast;