import { DEFAULT_TTL } from "../config/localStorageData";

export const setWithExpiry = (key, value, ttl) => {
	const now = new Date()
	if (typeof ttl === 'undefined') {
		ttl = DEFAULT_TTL;
	}

	// `item` is an object which contains the original value
	// as well as the time when it's supposed to expire
	const item = {
		value: value,
		expiry: now.getTime() + ttl,
	}
	localStorage.setItem(key, JSON.stringify(item))
}

export const getWithExpiry = (key) => {
	const itemStr = localStorage.getItem(key);
	if (!itemStr) {
		return undefined;
	}
	const item = JSON.parse(itemStr);
	const now = new Date();
	if (now.getTime() > item.expiry) {
		localStorage.removeItem(key);

		return undefined;
	}

	return item.value;
}

export const refreshKey = (key, newTtl) => {
	const value = getWithExpiry(key);

	if (typeof newTtl === 'undefined') {
		newTtl = DEFAULT_TTL;
	}

	if (typeof value !== 'undefined') {
		setWithExpiry(key, value, newTtl);
	}
}